<template>
  <BaseDialog :model-value="true" size-class="!max-w-4xl">
    <template #header>
      Terms & Conditions
      <div class="py-2 text-xs text-gray-400">
        The Tie Terminal has updated its Terms and Conditions. To continue using the platform and its features, please
        carefully review our documentation below.
      </div>
    </template>
    <template #default>
      <div class="max-h-96 overflow-y-auto rounded bg-gray-800 px-6 py-4">
        <img src="../../images/the_tie_logo_white.svg" class="h-4" />
        <p v-html="htmlFromMarkdown" class="prose prose-sm prose-invert !max-w-full pt-5 text-[12px] !text-white" />
      </div>
      <BaseCheckbox class="mt-4" v-model="agreeToTerms">
        <template #default>
          <span> I agree to the Terms and Conditions<span class="ml-1 text-red-500">*</span> </span>
        </template>
      </BaseCheckbox>
    </template>
    <template #footer>
      <div class="flex w-full justify-end">
        <BaseButton type="primary" :disabled="!agreeToTerms" @press="agreeToTermsOfService()">Continue</BaseButton>
      </div>
    </template>
  </BaseDialog>
</template>
<script setup>
import { ref, computed, onMounted } from 'vue';
import { marked } from 'marked';
import useHttp from '@/composeables/http';

const $http = useHttp();
const $emit = defineEmits(['close']);

const agreeToTerms = ref(false);

const htmlFromMarkdown = computed(() => {
  return marked(`## ACCEPTANCE OF THE TERMS OF USE
  These terms of use are entered into by and between You and The Tie Inc. ("The Tie," "Company," "We," "Our," or "Us). The following terms and conditions, together with any documents they expressly incorporate by reference (collectively, "Terms of Use"), govern your access to and use of www.thetie.io, and all of The Tie's subdomains including https://terminal.thetie.io, including any content, functionality, and services offered on or through www.thetie.io, and all of The Tie's subdomains (collectively the "Website" or "Site"), whether as a guest or a registered user.

  You represent that you (i) are of a legal age sufficient to agree to these Terms of Use in accordance with applicable law, (ii) have read, understand, and agree to be bound by these Terms of Use, and (iii) have all rights, permissions, and authorizations necessary to upload and provide all documents, content, and other materials that you choose to upload to the website, and if you opt into Our Messenger service (see section entitled **User Contributions and Messenger Service**), or choose to use Our Components Builder (see section entitled **Component Builder and The Tie Data Set Library**) to or through such service. If you are an entity and a subscriber to Our Website, any reference to You will also include your employees or your authorized users of our Website.

  Please read the Terms of Use carefully before you start to use the Website. By using the Website, you accept and agree to be bound and abide by these Terms of Use and Our Privacy Policy, found at https://www.thetie.io/privacy-policy, incorporated herein by reference. If you do not agree to these Terms of Use and/or the Privacy Policy, you must not access or use the Website.

  ## INTELLECTUAL PROPERTY RIGHTS
  You acknowledge that: (i) the Website, including all copyright, trademarks, service marks, logos, documents, graphics, research blogs, articles, content, and/or other materials viewed or obtained from or through the Website (collectively "Materials"), are owned and/or licensed by the Company or Our subsidiaries and affiliates, and are protected by trademark, copyright, and other intellectual property rights; and (ii) you have no rights to transfer, reproduce, or prepare any derivative works with respect to the Materials, or to disclose confidential information pertaining to the Website or Materials (herein sometimes referred to as the "Service"). These Terms of Use do not convey any rights of ownership in or related to the Website, Materials, or other intellectual property owned by the Company.

  The Company name, the term "The Tie Terminal™", the Company logo, and all related names, logos, product and service names, designs, and slogans are copyrights and/or trademarks of the Company or its affiliates or licensors. You must not use such marks without the prior written permission of the Company.

  The Website and its entire contents, features, and functionality, are owned by the Company, its licensors, or other providers of such Materials and are protected by United States and international copyright, trademark, patent, trade secret, and other intellectual property or proprietary rights laws.

  You must not reproduce, distribute, modify, create derivative works of, publicly display, publicly perform, republish, download, store, or transmit any of the Materials on Our Website, except as follows:
  + Your computer may temporarily store copies of such Materials incidental to your accessing and viewing those Materials;
  + You may store files that are automatically cached by your web browser for display enhancement purposes;
  + If We expressly provide you with certain content, you may take such actions as are enabled by such features.

  You must not:
  + modify copies of any Materials from the Website;
  + delete or alter any copyright, trademark, or other proprietary rights notices from copies of Materials from the Website;
  + access or use for any commercial purposes any part of the Website or any services or Materials available through the Website.

  If you wish to make any use of Materials on the website other than that set out in this section, please address your request to ben@thetie.io.

  If You print, copy, modify, download, or otherwise use or provide any other person with access to any part of the Website in breach of the Terms of Use, your right to use the Website will cease immediately and you must, at Our option, return or destroy any copies of the Materials you have made. No right, title, or interest in or to the Website or any content on the Website is transferred to you, and all rights not expressly granted are reserved by the Company. Any use of the Website not permitted by these Terms of Use is a breach of these Terms of Use and may violate copyright, trademark, and other laws.

  ## LICENSE
  The Company grants you a personal, revocable, non-exclusive, non-transferable, limited right and license to access the Website, provided that you comply with these Terms of Use. You may not modify, copy, distribute, broadcast, transmit, reproduce, publish, license, transfer, sell, scrape, mirror, or frame any Materials.

  ## CHANGES TO THE TERMS OF USE
  We may revise and update these Terms of Use from time to time and in Our sole discretion. All changes are effective immediately when We post them and apply to all access to and use of the Website thereafter. However, any changes to the dispute resolution provisions set forth in **Applicable Law; Jurisdiction and Venue** will not apply to any disputes for which the parties have actual notice on or prior to the date the change is posted on the Website.

  Your continued use of the Website following the posting of revised Terms of Use means that you accept and agree to the changes. You are expected to check this page frequently so you are aware of any changes, as they are binding on you.

  ## ACCESSING THE WEBSITE AND ACCOUNT SECURITY
  We reserve the right to withdraw or amend this Website, and any service or Material We provide on the Website, in Our sole discretion without notice. We will not be liable if for any reason at all any part of the Website is unavailable at any time or for any period. From time to time, We may restrict access to some parts of the Website, or the entire Website, to users, including registered users.

  You are responsible for both:
  + making all arrangements necessary for you to have access to the Website;
  + ensuring that all persons who access the Website through your internet connection are aware of these Terms of Use and comply with them.

  To access the Website or some of the resources it offers, you may be asked to provide certain registration details or other information. It is a condition of your use of the Website that all the information you provide on the Website is correct, current, and complete. You agree that all information you provide to register with the Website or otherwise, including, but not limited to, through the use of any interactive features on the Website, is governed by Our Privacy Policy https://www.thetie.io/privacy, and you consent to all actions We take with respect to your information consistent with [Our Privacy Policy](https://www.thetie.io/privacy).

  If you choose, or are provided with, a user name, password, or any other piece of information as part of Our security procedures, you must treat such information as confidential, and you must not disclose it to any other person or entity. You also acknowledge that your account is personal to you and agree not to provide any other person with access to this Website or portions of it using your user name, password, or other security information. You agree to notify Us immediately to security@thetie.io of any unauthorized access to or use of your user name or password or any other breach of security, including access to any portion of the Service that you are not expressly authorized to use or view. You also agree to ensure that you exit from your account at the end of each session. You should use particular caution when accessing your account from a public or shared computer so that others are not able to view or record your password or other personal information.

  We have the right to disable any user name, password, or other identifiers, whether chosen by you or provided by Us, at any time if, in Our opinion, you have violated any provision of these Terms of Use.

  ## USE OF THIS WEBSITE
  The Website and Materials are provided solely for your own informational purposes. Any unauthorized use is prohibited. You agree not to use the Website or Materials for any purpose that is unlawful or prohibited by these Terms of Use, or for any other purpose not reasonably intended by the Company. You further agree to abide by all applicable local, state, national, and international laws and regulations.

  Without limiting the generality of the foregoing, you will not, nor will you allow a third party to:
  + (a) copy, modify, or create derivative works of the Service, in whole or in part;
  + (b) rent, lease, lend, sell, license, sublicense, assign, distribute, publish, transfer, or otherwise make available the Service or any portion thereof;
  + (c) reverse engineer, disassemble, decompile, decode, adapt, or otherwise attempt to derive or gain access to any component of the Service, in whole or in part;
  + (d) remove any proprietary notices from the Service;
  + (e) use any part of the Service (including without limitation, Messenger, Component Builder, free-form python editors, and SQL queries) in any manner or for any purpose that infringes, misappropriates, or otherwise violates any intellectual property right or any other right of any person, or that violates any applicable law;
  + (f) combine or integrate the Service with any software, technology, services, or materials not authorized by Us;
  + (g) attempt to cloak or conceal a User's identity when using the Service;
  + (h) use the Service to store or transmit infringing, libelous, or otherwise unlawful or tortious material, or to store or transmit material in violation of third-party privacy rights;
  + (i) interfere with or disrupt the integrity or performance of the Service;
  + (j) copy or attempt to reproduce the Service or any part, feature, function, or user interface thereof;
  + (k) copy, extract, or store Content except as expressly permitted; or
  + (l) access the Service in order to build or enhance a competitive Service or service.

  ## USER CONTRIBUTIONS AND MESSENGER SERVICE
  If you have subscription access to Our The Tie Terminal™ (subdomain https://terminal.thetie.io/) it may include The Tie Messenger ("Messenger"). Messenger is defaulted to "off." You have no obligation to opt in or to use Messenger. By opting into Messenger, you assume full responsibility to ensure legally compliant and authorized use by you, your designated compliance officer, if any, and each of the employees or agents that You authorize to use Our Service ("your users"). You also assume full responsibility for your and your users' interaction with any Messenger users outside of your company to whom you or your users send or receive messages or content. You further acknowledge that by opting into Messenger, your name and/or the name and title of your users (along with certain information which may be included at the option of your users) will be visible to other Messenger users, unless you or your users mark themselves as not visible on Messenger. We do not screen or filter the content of any messages sent via Messenger (whether for offensive or illegal content, viruses, or otherwise); nor do We modify such messages or content. Our Website from time to time may also contain message boards, chat rooms, personal web pages or profiles, forums, bulletin boards, or other interactive features (collectively, "Interactive Services") that allow users to post, publish, submit, display, or transmit to other users or other persons (hereinafter, "post") content or materials, on or through the Website.

  Under no circumstances will We be liable in any way in connection with use of The Tie Messenger or any Interactive Services including, but not limited to, any errors or omissions, or any loss or damage of any kind incurred because of the use of any messages or content transmitted or otherwise made available through our Website.

  **No Liability for Third-Party Communications.** We disclaim all liability for any third-party communications you may receive or any actions you may take or refrain from taking as a result of any communication directed to you from any third party directly or indirectly in connection with User Contributions, Messenger, or any other services provided by Us. You are solely responsible for assessing and verifying the identity and trustworthiness of the source and content of any third-party communication. We assume no responsibility for verifying, and make no representations or warranties regarding, the identity or trustworthiness of the source or content of any third-party communication.

  To the extent that you elect to upload or input any content on Messenger or the Website, you agree as follows:
  + (a) "**User Contributions**" means any comments, feedback, chats or other material or content posted or submitted in any forums or other shared areas of Messenger or the Website. You grant Us a worldwide, perpetual, irrevocable, royalty-free license to use all User Contributions in Our discretion throughout Our business for purposes of providing the Website. The Website is not designed or intended to serve as a repository of personally identifiable or health-related information, and The Tie makes no warranty as to the manner in which such information would be held. You agree, represent, and warrant that you shall not collect for marketing purposes any email addresses or other personal information that has been posted by other users of the Site.
  + (b) **User Representations.** You agree,  represent, and warrant that no User Contributions you submit shall:
    * violate or infringe on the rights of others including, without limitation, patent, trademark, trade secret, copyright, publicity, privacy, or other proprietary rights;
    * allow you to gain unauthorized access to the Site, or any account, computer system, or network connected to the Site, by means such as hacking, password mining, or other illicit means;
    * be unlawful, threatening, abusive, harassing, defamatory, libelous, deceptive, fraudulent, invasive of another's privacy, tortious, or contain explicit, graphic, objectionable, or offensive depictions or accounts;
    * victimize, harass, degrade, or intimidate an individual or group of individuals on the basis of religion, gender, sexual orientation, race, ethnicity, age, or disability;
    * impersonate any person, business or entity, including The Tie, any of its affiliated entities, or any of their employees or agents or falsely state or otherwise misrepresent your affiliation with any person, business or entity, including The Tie or any of its affiliated entities;
    * contain an advertisement or solicitation or encourage others to make a donation;
    * contain viruses or any other computer code, files, or programs that interrupt, destroy or limit the functionality of any computer software or hardware or telecommunications equipment, or otherwise permit the unauthorized use of a computer or computer network;
    * encourage conduct that would constitute a criminal offense or that gives rise to civil liability or that otherwise encourages others to commit illegal activities or cause injury or property damage to any other person;
    * result in the posting or transmission of any message anonymously or under a false name;
    * permit any person to access, using your account, any features of the Site that may require registration;
    * result in a single message being posted to excessive locations throughout the Site, result in messages being posted to any part of the Site if that message is, in Our view, off-topic or in violation of these Terms of Use; or
    * violate these Terms of Use, guidelines, or any policy posted on the Site, or that interferes with the use of the Site by others.
  + (c) **Disclaimer and Safe Harbor.** The Tie has no obligation to retain or store any User Contributions. The Tie makes no representations as to the accuracy, non-infringement, or validity of any User Contributions. The Tie does not monitor Messenger or any User Contributions; however, The Tie reserves the right to remove, delete, or refuse to post any User Contributions and to disable access to Messenger if We feel that such User Contributions or further use by you may violate these Terms of Use, including any of the prohibited types of content set forth above, or otherwise in Our discretion. Notwithstanding this right of Ours, users shall remain solely responsible for the content they post on Our Website. You acknowledge and agree that neither The Tie nor any of its affiliates shall assume or have any liability for any action or inaction by The Tie with respect to any conduct within the Website or any communication or posting on the Website. The Tie also reserves the right to disclose any information that The Tie believes necessary to satisfy any law, regulation or governmental request, or refuse to post or to remove any information or materials, in whole or in part. You waive and hold Us harmless from any claims resulting from action taken by Us as a result of such cooperation.

  You represent and warrant that:
  + you own or control all rights in and to the User Contributions and have the right to grant the license granted above to Us and Our affiliates and service providers, and each of their and Our respective licensees, successors, and assigns;
  + all of your User Contributions do and will comply with these Terms of Use.

  You understand and acknowledge that you are responsible for any User Contributions you submit or contribute, and you, not the Company, have full responsibility for such content, including its legality, reliability, accuracy, and appropriateness.

  ## COMPONENT BUILDER AND THE TIE DATA SET LIBRARY
  If you have subscription access to Our The Tie Terminal™ (subdomain https://terminal.thetie.io/) Our Service includes Component Builder, which permits Users to combine several third-party APIs with the data in Our The Tie Data Set Library to create custom metrics and components. These APIs are both (1) offered by Us as part of the Service ("Our Partner APIs"), and (2) a pre-loaded catalog of endpoints provided by Us to enable you to utilize the APIs of third-party vendors from whom you license data for use in accordance with such third-party vendor terms. If you use Our Partner APIs, you agree to abide by the terms and conditions of Our Partner APIs and expressly acknowledge that any data provided thereby is provided for display purposes only. If you integrate data from third-party APIs, you represent and warrant that you have authority to do so and assume all risk and liability for any data integration.

  You recognize and agree that hosting data online involves risks of unauthorized disclosure or exposure and that by using the Service, you assume such risks. We offer no representation, warranty, or guarantee that information provided by you or your users and any other data uploaded by your users will not be exposed or disclosed through errors or the action of third parties. We shall have no responsibility or liability for the accuracy, availability, or any other aspect of data uploaded to the Service by you or your Users.

  ## LINKS
  Our Website may contain links to third-party websites, services, or resources that We do not own or control. These links are provided for your convenience only. No endorsement of any third-party products, services, or information is expressed or implied by any information, material, or content of any third party contained in, referred to, included on, or linked from or to the Website. Any information, data, opinions, recommendations, products, or services provided by such third parties through links to other websites or otherwise made available through their websites are solely those of such third parties. We have no control over, and assume no responsibility for, the content, privacy policies, or practices of any third-party websites or services. We strongly advise you to read the terms and conditions and privacy policies of any third-party websites or services that you visit.

  ## INDEMNIFICATION
  To the extent permissible by law, you will indemnify, defend, and hold harmless the Company and its subsidiaries, affiliates, officers, directors, managers, employees, agents, vendors, merchants, sponsors, providers, and licensors from any and all losses, deficiencies, judgments, damages, liabilities, costs, and expenses (including, but not limited to, reasonable attorneys' fees and all related costs and expenses) incurred by them as a result of any claim, lawsuit, demand, cause of action, or proceeding brought by a third party arising from any or all of the following: (i) your access, inability to access, or use of the Website; (ii) any of your User Contributions; (iii) breach of any of your obligations, representations, or warranties in these Terms of Use; or (iv) your violation of any rights of another person.

  ## DISCLAIMER OF WARRANTIES
  OTHER THAN AS EXPRESSLY SET OUT HEREIN, THE WEBSITE AND MATERIALS ARE PROVIDED ON AN "AS IS" AND "AS AVAILABLE" BASIS, WITH ALL FAULTS, AND WITHOUT WARRANTY OF ANY KIND. THE COMPANY AND ITS SUPPLIERS SPECIFICALLY DISCLAIM ALL WARRANTIES OF ANY KIND, EITHER EXPRESS, IMPLIED, OR STATUTORY, INCLUDING, BUT NOT LIMITED TO, ANY WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, QUIET ENJOYMENT, QUALITY OF INFORMATION, NON-INFRINGEMENT, AND TITLE. NO WARRANTY IS MADE THAT THE WEBSITE WILL BE TIMELY, SECURE, OR ERROR-FREE. IN JURISDICTIONS NOT ALLOWING THE LIMITATION OR EXCLUSION OF CERTAIN WARRANTIES, THE COMPANY'S WARRANTY SHALL BE LIMITED TO THE GREATEST EXTENT PERMITTED BY LAW.

  ## LIMITATION OF LIABILITY
  Where any information on the Website is provided free-of-charge, you acknowledge that it would be unreasonable to hold Us liable with respect to the Website and the information therein contained. You expressly acknowledge that We aggregate third-party content ("Third-Party Content") and also provide research and other reports prepared by The Tie team. We supply such information solely for your interest. The Third-Party Content is not intended to provide tax, legal, or investment advice. We make an effort to obtain Third-Party Content from sources believed to be reliable; however, no express or implied guarantee is made by Us as to the accuracy, completeness, or timeliness. Third-Party Content is not created or endorsed by Us; neither We nor any partner or affiliate has any liability in connection with the use of Third-Party Content and no affiliation with or connection to any business offering services through it. You agree not to hold Us liable for any decision or other transaction that you may make based on reliance on or use of Third-Party Content. For investment advice, consult a registered investment advisor. The Tie may have been paid to produce any research or other reports provided on Our Website.

  You acknowledge that We have no control as to the availability of Third-Party content and that, from time to time, individual sources may become inoperable for reasons outside of Our control.

  REGARDLESS OF THE TYPE OF CLAIM OR THE NATURE OF THE CAUSE OF ACTION, TO THE EXTENT ALLOWED BY LAW, YOU UNDERSTAND AND EXPRESSLY AGREE THAT IN NO EVENT WILL THE COMPANY OR ITS AFFILIATES, OFFICERS, EMPLOYEES, AGENTS OR LICENSORS BE LIABLE FOR: (I) ANY INDIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL, EXEMPLARY, OR PUNITIVE DAMAGES, INCLUDING, BUT NOT LIMITED TO, LOSS OF REVENUES, DATA AND PROFITS, OR OTHER INTANGIBLE LOSSES, EVEN IF THE COMPANY HAS BEEN ADVISED AS TO THE POSSIBILITY OF SUCH DAMAGES; OR (II) TOTAL CUMULATIVE LIABILITY ARISING UNDER OR RELATED TO THE WEBSITE, WHETHER IN CONTRACT, TORT, OR OTHERWISE. SOME JURISDICTIONS DO NOT ALLOW THE LIMITATION OR EXCLUSION OF LIABILITY FOR INCIDENTAL OR CONSEQUENTIAL DAMAGES. IN THOSE JURISDICTIONS, THE COMPANY'S LIABILITY SHALL BE LIMITED TO THE GREATEST EXTENT PERMITTED BY LAW. YOU UNDERSTAND AND AGREE THAT YOUR USE OF THE WEBSITE IS PREDICATED UPON YOUR WAIVER OF ANY RIGHT TO SUE THE COMPANY, ITS OWNERS OR AFFILIATES DIRECTLY, OR TO PARTICIPATE IN A CLASS ACTION SUIT FOR ANY LOSSES OR DAMAGES RESULTING FROM YOUR USE OF THE WEBSITE.

  IN ALL CASES, NEITHER THE COMPANY NOR ITS SUPPLIERS ASSUME ANY RESPONSIBILITY OR LIABILITY FOR ANY ERRORS OR OMISSIONS ON THE WEBSITE OR WITH RESPECT TO WEBSITE MATERIALS, ANY FAILURES, DELAYS, OR INTERRUPTIONS IN THE WEBSITE'S ACCESSIBILITY, ANY LOSSES OR DAMAGES ARISING FROM THE USE OF THE WEBSITE OR WEBSITE MATERIALS, ANY CONDUCT BY OTHER USERS OF THE WEBSITE, OR UNAUTHORIZED ACCESS TO OR USE OF THE WEBSITE OR WEBSITE MATERIALS.

  ## APPLICABLE LAW; JURISDICTION AND VENUE
  We control Our Website from our offices within the United States. We make no representation that the Materials on Our Website are appropriate, legal, or available for use in other locations. You may not use or export the Materials in violation of United States export laws and regulations. Any claim relating to the Website or Materials shall be governed by the laws of the State of New York, without reference to its choice of law provisions. Unless otherwise provided in an Agreement executed by Us and you, you agree that the exclusive venue and jurisdiction for any controversy, dispute, or claim arising out of or relating to the Website or Materials shall be the federal and state courts located in New York. You submit to the exclusive venue and jurisdiction of such courts, agree that you will not bring any suit or judicial proceeding in any forum other than in such courts, and agree not to assert any objection you may have to the venue or jurisdiction of such courts. In the event the Company employs attorneys to enforce any right arising out of or relating to the Website or Materials, you shall reimburse the Company its reasonable attorneys' fees and costs.

  ## SEVERABILITY
  If any part of these Terms of Use is determined to be invalid or unenforceable pursuant to applicable law, including, but not limited to, the warranty disclaimers and the liability limitations set forth above, then the invalid or unenforceable provision will be deemed superseded by a valid, enforceable provision that most clearly matches the intent of the original provision, and the remainder of these Terms of Use shall continue in effect.

  ## ASSIGNMENT
  The Company is permitted to assign, transfer, and subcontract its rights and/or obligations under these Terms of Use without any notification. However, you are not allowed to assign, transfer, or subcontract any of your rights and/or obligations under these Terms of Use.

  ## DIGITAL MILLENNIUM COPYRIGHT ACT
  If you believe that any Materials or User Contributions infringe your copyright, you (or your agent) may send Us notice requesting that such Materials or User Contributions be removed. Notice must be provided in writing and must include the following information:
  + a signature of the person authorized to act on behalf of the owner of the copyright interest;
  + identification of the copyrighted work that you claim has been infringed;
  + the location of the work you claim is infringing (e.g., URL) or enough detail that We may find it;
  + your name, address, telephone number, and e-mail address;
  + a statement by you that you have a good faith belief that the disputed use is not authorized by the copyright owner, its agent, or the law;
  + a statement, made under penalty of perjury, that the information in your notice is accurate and that you are the copyright owner or are authorized to act on the copyright owner's behalf.

  ## MONITORING AND ENFORCEMENT; TERMINATION
  We have the right to:
  + remove or refuse to post any User Contributions for any or no reason in Our sole discretion;
  + take any action with respect to any User Contributions that We deem necessary or appropriate in Our sole discretion, including if We believe that such User Contributions violate the Terms of Use, including the content standards set forth herein, infringe upon any intellectual property right or other right of any person or entity, threaten the personal safety of users of the Website or the public, or could create liability for the Company;
  + disclose your identity or other information about you to any third party who claims that material posted by you violates their rights, including their intellectual property rights or the right to their privacy;
  + take appropriate legal action, including, without limitation, referral to law enforcement, for any illegal or unauthorized use of the Website;
  + terminate or suspend your access to all or part of the Website for any or no reason, including, without limitation, any violation of these Terms of Use.

  Without limiting the foregoing, We have the right to fully cooperate with any law enforcement authorities or court order requesting or directing Us to disclose the identity or other information of anyone posting any materials on or through the Website. YOU WAIVE AND HOLD HARMLESS THE COMPANY AND ITS AFFILIATES, LICENSEES, AND SERVICE PROVIDERS FROM ANY CLAIMS RESULTING FROM ANY ACTION TAKEN BY THE FOREGOING PARTIES DURING OR AS A RESULT OF ITS INVESTIGATIONS AND FROM ANY ACTIONS TAKEN AS A CONSEQUENCE OF INVESTIGATIONS BY EITHER SUCH PARTIES OR LAW ENFORCEMENT AUTHORITIES.

  However, We do not undertake to review all material before it is posted on the Website and cannot ensure prompt removal of objectionable material after it has been posted. Accordingly, We assume no liability for any action or inaction regarding transmissions, communications, or content provided by any user or third party. We have no liability or responsibility to anyone for performance or nonperformance of the activities described in this section.

  ## CHANGES IN THE WEBSITE
  We may update the content on the Website from time to time, but its content is not necessarily complete or up to date. Any of the material on the Website may be out of date at any given time, and we are under no obligation to update such material.

  ## INFORMATION ABOUT YOU AND YOUR VISITS TO THE WEBSITE
  All information We collect on the Website is subject to Our [Privacy Policy](https://www.thetie.io/privacy-policy/). By using the Website, you consent to all actions taken by Us with respect to your information in compliance with Our Privacy Policy.

  ## LIMITATION ON TIME TO FILE CLAIMS
  ANY CLAIM OR CAUSE OF ACTION ARISING FROM THESE TERMS OF USE OR USE OF THE WEBSITE MUST BE FILED WITHIN ONE (1) YEAR OR SUCH CLAIM OR CAUSE OF ACTION IS FOREVER BARRED.

  ## WAIVER AND SEVERABILITY
  No waiver by the Company of any term or condition set forth in these Terms of Use shall be deemed a further or continuing waiver of such term or condition or a waiver of any other term or condition, and any failure of the Company to assert a right or provision under these Terms of Use shall not constitute a waiver of such right or provision.

  If any provision of these Terms of Use is held by a court or other tribunal of competent jurisdiction to be invalid, illegal, or unenforceable for any reason, such provision shall be eliminated or limited to the minimum extent such that the remaining provisions of the Terms of Use will continue in full force and effect.

  ## GENERAL INQUIRIES
  Please send general inquiries regarding intellectual property issues to legal@thetie.io.

  **Copyright Agent for Copyright Infringement Claims (Digital Millennium Copyright Act):**

  The designated agent to receive notification of claimed infringement under Title II of the Digital Millennium Copyright Act is: legal@thetie.io.
  `);
});

async function agreeToTermsOfService() {
  await $http.post('/terms_of_service');
  $emit('close');
}

onMounted(() => {
  const renderer = new marked.Renderer();
  renderer.link = function (href, title, text) {
    return `<a target="_blank" href="${href}">${text}` + '</a>';
  };
  marked.setOptions({
    renderer: renderer
  });
});
</script>
